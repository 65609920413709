import React from 'react';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from "../../../store/hooks";
import { RoutesGuest } from './routesByRole/RoutesGuest';
import { RoutesAdmin } from "./routesByRole/RoutesAdmin";
import { RoutesAuditor } from "./routesByRole/RoutesAuditor";
import { ProjectUserRoles } from "../../../constants";
import { RoutesClient } from "./routesByRole/RoutesClient";

export const AppRouting = observer(() => {
  const authStore = useAuthStore();
  const {role} = authStore;

  const setRoutesByRole = () => {
    switch (role) {
      case ProjectUserRoles.ROLE_GUEST:
        return <RoutesGuest/>;
      case ProjectUserRoles.ROLE_ADMIN:
        return <RoutesAdmin/>;
      case ProjectUserRoles.ROLE_CLIENT:
        return <RoutesClient/>
      case ProjectUserRoles.ROLE_AUDITOR:
        return <RoutesAuditor/>
      default:
        return <RoutesGuest/>;
    }
  }

  return setRoutesByRole();
});
